<template>
  <div class="user-share">
    <van-notice-bar
      left-icon="volume-o"
    >
      <span v-for="(item, index) in noticeList" :key="index">
        {{ item.nickName }}用户刚刚提现了{{ item.amount | changeUnit }}元！
      </span>
    </van-notice-bar>
    <div class="invite-main">
      <div class="com-box">
        <div class="com-box-mid share-box">
          <img :src="bgImg" alt="">
          <div class="share-list">
            <div v-for="item in shareList" :key="item.id" class="item" @click="goShare(item.des)">
              <div class="pic"><img :src="item.imgUrl" class="img"></div>
              <div class="title">{{ item.title }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="com-box com-box-main">
        <div class="com-box-main-head"><img :src="mainTitle" alt=""></div>
        <div class="com-box-mid2">
          <div class="com-box-mid2-cont">
            <div class="mid2-head">
              <div class="link" @click="goShareRule">活动规则</div>
              <div class="btn" @click="goWithdraw">提现</div>
            </div>
            <div class="my-record">
              <div class="top" @click="goIncome">
                <div class="item">
                  <div class="num">{{ inviteCount }}<span class="unit">人</span></div>
                  <div class="des">邀请战绩</div>
                </div>
                <div class="item">
                  <div class="num">{{ userIncome.totalIncome | changeUnit }}<span class="unit">元</span></div>
                  <div class="des">收益统计</div>
                </div>
              </div>
              <div class="list">
                <div class="head">
                  <div class="name">姓名</div>
                  <div class="total">贷款金额(万)</div>
                  <div class="price">我的收益(元)</div>
                </div>
                <div v-for="item in inviteList" :key="item.id" class="item">
                  <div class="name">{{ item.sourceRealName }}</div>
                  <div class="total">{{ item.loanAmount | changeWang }}</div>
                  <div class="price">{{ item.incomeAmount | changeUnit }}</div>
                </div>
              </div>
              <div class="bottom">
                <template v-if="finished">
                  <div class="text">没有更多了！</div>
                </template>
                <template v-else>
                  <van-loading v-if="loading" size="24px" color="#F08E71" />
                  <div v-else class="btn" @click="loadMore"><van-icon name="arrow-down" /></div>
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="com-box-bottom">
        <img :src="bgBottom" style="display: block;">
      </div>
    </div>
  </div>
</template>
<script>
import bgImg from '@/assets/user/bg.jpg'
import mainTitle from '@/assets/user/main_title.png'
import bgBottom from '@/assets/user/bg_bottom.jpg'
import wxIcon from '@/assets/user/wx@2x.png'
import pyqIcon from '@/assets/user/pyq@2x.png'
import { mapGetters } from 'vuex'
import { setShare } from '@/utils/wx.config'
import { getUserIncome, listUserIncomeRecordOfSharePage, listWithdrawalDataWithShareHeader } from '@/api/user'
export default {
  filters: {
    // 将单位转化为元
    changeUnit(val) {
      if (!val) return 0.00
      return (val / 100).toFixed(2)
    },
    changeWang(val) {
      if (!val) return 0.00
      const num = Math.round(val / 10000) / 100
      console.log(num)
      return num.toFixed(2)
    }
  },
  data() {
    return {
      userIncome: {},
      inviteCount: 0,
      noticeList: [],
      time: 0,
      shareList: [{
        title: '微信',
        des: '转发给朋友',
        imgUrl: wxIcon,
        id: 1
      },
      {
        title: '朋友圈',
        des: '分享到朋友圈',
        imgUrl: pyqIcon,
        id: 2
      }
      ],
      inviteList: [],
      queryType: 1,
      params: {
        limit: 3,
        page: 1
      },
      loading: true,
      finished: false,
      bgImg,
      mainTitle,
      bgBottom
    }
  },
  computed: {
    ...mapGetters([
      'shareUrls'
    ])
  },
  mounted() {
    this.getUserIncome()
    this.getInviteList()
    this.getNoticeList()
    this.initWx()
  },
  methods: {
    goShareRule() {
      this.$router.push({ name: 'shareRule' })
    },
    goShare(title) {
      this.$toast(`请点击右上角三个点，选择${title}进行分享！`)
    },
    goWithdraw() {
      this.$router.push({ name: 'userWithdrawal' })
    },
    goIncome() {
      this.$router.push({ name: 'userIncome' })
    },
    async initWx() {
      const loading = this.$toast.loading({ forbidClick: true, duration: 0 })
      await this.$store.dispatch('wx/initWxConfig', window.location.href.split('#')[0])
      await this.$store.dispatch('user/getShareUrls', {
        keys: [
          'invite_share'
        ]
      })
      const link = this.shareUrls.invite_share
      setShare({
        title: '用最少的钱，贷更多的钱',
        desc: '猫嘀嘀推崇客户至上的理念，让您体验极致贷款服务',
        link,
        imgUrl: 'https://www.maodidikeji.com/user/assets/logo.png'
      })
      loading.clear()
    },
    loadMore() {
      this.page++
      this.getInviteList()
    },
    async getNoticeList() {
      const loading = this.$toast.loading({ forbidClick: true, duration: 0 })
      const res = await listWithdrawalDataWithShareHeader()
      this.noticeList = res.datas || []
      loading.clear()
    },
    async getUserIncome() {
      const loading = this.$toast.loading({ forbidClick: true, duration: 0 })
      const res = await getUserIncome()
      this.userIncome = res.datas || []
      this.inviteCount = this.userIncome.friendInviteCount + this.userIncome.myInviteCount
      loading.clear()
    },
    async getInviteList() {
      const res = await listUserIncomeRecordOfSharePage(this.params)
      this.inviteList = [...this.inviteList, ...res.datas.data]
      this.loading = false
      if (this.inviteList.length === res.datas.count * 1) {
        this.finished = true
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.user-share {
  .invite-main{
    .my-record{
        padding-top:.533333rem;
        .top{
            display: flex;
            justify-content: center;
            .item{
                text-align: center;
                color: #912B32;
                .num{
                    font-size: .64rem;
                    .unit{
                        font-size: .32rem
                    }
                }
                .des{
                    font-size: .32rem
                }
                &:first-child{
                    padding-right: .666667rem;
                }
                &:last-child{
                    padding-left: .666667rem;
                }
            }
        }
        .list{
            padding: .8rem .1rem 0 .1rem;
            font-size: .373333rem;
            text-align: center;
            .head{
                display: flex;
                font-size: .32rem;
                padding: .16rem;
                color: #912B32;
                border-radius: .066667rem;
                overflow: hidden;
                background: #FFC6B1;
            }
            .item{
                display: flex;
                padding: .2rem 0;
                color: #672B2D;
                .total,.price{
                    text-align: left;
                    padding-left: .8rem;
                }
            }
            .name{
                width: 2.4rem;
                padding-left: .24rem;
                text-align: left;
                box-sizing: border-box;
            }
            .total{
                width: 3.066667rem;
                box-sizing: border-box;
            }
            .price{
                flex:1;
                box-sizing: border-box;
            }
        }
        .bottom{
            height: .8rem;
            text-align: center;
            .btn{
                img{
                    width: .586667rem;
                    height: .586667rem;
                }
            }
            .text{
                padding-top: .2rem;
                font-size: .4rem;
                color:#672B2D;
            }
        }
    }
  }
}
.share-box {
    position: relative;
}
.share-box img {
    display: block;
}
.share-list{
    position: absolute;
    top: 11rem;
    left: 0;
    width: 100%;
    text-align: center;
}
.share-list .item{
    display: inline-block;
    padding: 0 .533333rem;
}
.share-list .item .pic .img{
    width: 1.066667rem;
    height: 1.066667rem;
}
.share-list .item .title{
    color: #fff;
    font-size: .32rem;
    padding-top: 5px;
}
.invite-des{
    text-align: center;
    padding:.533333rem 0;
    font-size: 0;
    img{
        width:100%;
    }
}

.invite-box{
    padding:.533333rem 0;
}
.com-box-main {
    position: relative;
    padding: 1rem .6rem .3rem .6rem;
    background-image: url(~@/assets/user/bg_mid.jpg);
    background-repeat: repeat-y;
    background-size: 100%;
    .com-box-main-head {
        position: absolute;
        width: 100%;
        top: .62rem;
        left: 0;
        z-index: 999;
        text-align: center;
        img {
            width: 5.093333rem;
        }
    }
    .mid2-head {
        display: flex;
        justify-content: space-between;
        align-items: center;
        text-align: right;
        padding: .3125rem 0.3125rem 0;
        line-height: 1;
        .link {
          color: #EB333F;
        }
        .btn{
            display: inline-block;
            width: 1.4rem;
            height: .486667rem;
            line-height: .486667rem;
            font-size: .3rem;
            color:#EB333F;
            border-radius: 10px;
            border: 1px solid #EB333F;
            text-align: center;
        }

    }
}
.com-box-mid2{
    position: relative;
    padding: 1rem .2rem .3rem .2rem;
    border-radius: 6px;
    background: #CF212C;
    box-shadow: 0px .08rem .213333rem rgba(207, 33, 44, 0.5);
}
.com-box-mid2-cont {
    border-radius: 12px;
    background: #F9F5E7;
}
.com-box-bottom{
    position: relative;
}
</style>
